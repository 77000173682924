<template>
  <div class="main">
    <div class="title">报警管理</div>
    <!-- 列表信息 -->
    <div class="listBox">
      <div class="info">报警信息列表</div>
      <!-- 列表信息 -->
      <div class="list">
        <el-table
          ref="multipleTable"
          border
          :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; color: rgba(0, 0, 0, 0.85)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="deviceName"
            align="center"
            label="报警设备"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="alarmType"
            align="center"
            label="报警类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="报警级别"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.alarmLevel }}级
            </template>
          </el-table-column>
          <el-table-column
            prop="alarmTime"
            align="center"
            label="报警时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <!-- 处理报警操作 -->
              <div v-if="scope.row.alarmState == 1" class="handleoper">
                <span
                  style="color: #52c41a"
                  @click="getHandleInfoDetail(scope.row.id)"
                  >已处理</span
                >
              </div>
              <div
                class="handleoper"
                v-else
                style="color: #1a4382"
                @click="handleAlarm(scope.row.id)"
              >
                处理报警
                <span class="redCircle"></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 50, 100, 200, 300, 400]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 处理报警弹框 -->
    <el-dialog title="处理报警" :visible.sync="AlarmVisible" width="700px">
      <el-form
        :model="form"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
        style="padding: 0 100px"
      >
        <el-form-item label="处理人：" prop="people">
          <el-input
            style="width: 320px"
            placeholder="请输入处理人"
            v-model="form.people"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理时间：" prop="date">
          <!-- <el-col :span="18"> -->
          <el-date-picker
            type="datetime"
            style="width: 320px"
            placeholder="请选择处理时间"
            v-model="form.date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
          ></el-date-picker>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="处理内容：" prop="content">
          <!-- <el-col :span="18"> -->
          <el-input
            type="textarea"
            style="width: 320px"
            placeholder="请输入处理内容"
            v-model="form.content"
          ></el-input>
          <!-- </el-col> -->
        </el-form-item>
        <!-- <el-form-item label="处理状态:" prop="status" style="margin-left: -10px;">
          <el-radio-group v-model="form.status" style="margin-left: 10px;">
            <el-radio label="1">已处理</el-radio>
            <el-radio label="0">未处理</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleFrom('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 已处理弹框 -->
    <el-dialog
      title="已处理报警详情"
      :visible.sync="AlreadyVisible"
      width="500px"
      style="padding: 0 20px"
    >
      <el-form
        :model="form"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
        style="padding: 0 100px"
      >
        <el-form-item label="处 理 人：" prop="people">
          <span>{{ handlePeople }} </span>
        </el-form-item>
        <el-form-item label="处理时间：" prop="date">
          <!-- <el-col :span="18"> -->
          <span>{{ handleTime }}</span>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="处理内容：" prop="content">
          <!-- <el-col :span="18"> -->
          <span style="display: inline-block; width: 200px">{{
            handleContent
          }}</span>
          <!-- </el-col> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 列表信息
      tableData: [],
      // 报警id
      alarmId: "",
      // 选择列表id
      multipleSelection: [],
      // 分页
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      // 已处理弹框信息
      AlreadyVisible: false,
      // 已处理信息
      handleInfo: {
        processPerson: "",
        processTime: "",
        processResult: "",
      },
      // 处理人
      handlePeople: "",
      // 处理时间
      handleTime: "",
      // 处理内容
      handleContent: "",
      // 报警弹框信息
      AlarmVisible: false,
      form: {
        people: "",
        date: "",
        content: "",
        status: "",
      },
      // 弹框规则
      rules: {
        people: [
          { required: true, message: "请输入处理人", trigger: "change" },
        ],
        date: [
          {
            type: "date",
            required: true,
            message: "请选择处理时间",
            trigger: "change",
          },
        ],
        content: [
          { required: true, message: "请输入处理内容", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择处理状态", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getAlarmData();
    this.watchStorage();
  },
  methods: {
    // 监控locaStorage
    watchStorage() {
      const that = this;
      window.addEventListener("setItemEvent", function (e) {
        // 监听setitem的 key ，执行对应的业务逻辑
        console.log(e.key, e.value);
        if (e.key === "projectID") {
          const newProjectId = e.value;
          that.getAlarmData(newProjectId);
        }
      });
    },
    //  请求报警列表
    getAlarmData(newProjectId) {
      let arr = [];
      let projectID = localStorage.getItem("projectID");
      // console.log(newProjectId);
      if (newProjectId) {
        arr.push(newProjectId);
      } else {
        arr.push(projectID);
      }
      let param = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        projectList: arr,
      };
      this.$post("/alarm/pageList", param)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.tableData = res.data.list;
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取已处理报警信息详情
    getHandleInfoDetail(id) {
      this.AlreadyVisible = true;
      this.handlePeople = "";
      this.handleTime = "";
      this.handleContent = "";
      let param = {
        id: id,
      };
      this.$get("/alarmProcess/load", param)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.handleInfo = res.data;

            this.handlePeople = res.data.processPerson;
            this.handleTime = res.data.processTime;
            this.handleContent = res.data.processResult;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 时间戳转换
    timeChange(value) {
      var date = new Date(value);
      var year = date.getFullYear();
      var mon = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      mon = parseInt(mon) < 10 ? "0" + mon : mon;
      day = parseInt(day) < 10 ? "0" + day : day;
      hour = parseInt(hour) < 10 ? "0" + hour : hour;
      min = parseInt(min) < 10 ? "0" + min : min;
      sec = parseInt(sec) < 10 ? "0" + sec : sec;
      let d = year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + sec;
      return d;
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
      });

      console.log(this.multipleSelection);
    },
    // 处理报警
    handleAlarm(id) {
      this.alarmId = id;
      this.AlarmVisible = true;
    },
    // 处理报警确认键
    HandleAlarmComfrim() {
      console.log(this.form.date);
      let projectID = localStorage.getItem("projectID");
      let param = {
        alarmId: this.alarmId,
        projectId: projectID,
        processPerson: this.form.people,
        processTime: this.timeChange(this.form.date),
        processResult: this.form.content,
        processStatus: 1,
      };
      console.log(param);
      this.$post("/alarmProcess/saveOrUpdate", param)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.getAlarmData();
            this.watchStorage();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      console.log(this.pageSize);
      this.getAlarmData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      console.log(`当前页: ${val}`);
      this.getAlarmData();
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.HandleAlarmComfrim();
          this.AlarmVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //   取消
    cancleFrom(formName) {
      this.$refs[formName].resetFields();
      this.AlarmVisible = false;
    },
  },
};
</script>
<style>
.el-form-item__content {
  width: 500px;
}
</style>
<style lang="scss" scoped>
.main {
  margin: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-family: PingFang SC;
  font-size: 16px;
  .listBox {
    margin: 10px 0;
    background: #fff;
    padding: 10px;
    .list {
      margin: 20px auto;
    }
    // 处理报警操作
    .handleoper {
      position: relative;
      cursor: pointer;
      .redCircle {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: red;
        border-radius: 50%;
        position: absolute;
        top: 0;
      }
    }

    // 分页
    .page {
      width: 100%;
      text-align: right;
    }
  }
}
</style>
